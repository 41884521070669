import React, { useState, useCallback, useRef, useEffect } from "react";
import {
  Editor,
  EditorState,
  RichUtils,
  CompositeDecorator,
  Modifier,
  SelectionState,
  getDefaultKeyBinding,
  ContentState,
  convertFromHTML,
  ContentBlock,
} from "draft-js";
import "draft-js/dist/Draft.css";
import { LinkSuggestionList } from "./LinkSuggestion";
import { RedFlagList } from "./MasterWords.js";
import LinkCountSidebar from "./LinkCountSidebar";
import { getNewSuggestionList, getSelectedWordCount } from "./utils/utility";
import { defaultWebsiteUrl, plagiarismCheckerPageUrl } from "./constants";

let LINK_SUGGESTIONS = getNewSuggestionList(defaultWebsiteUrl);

// New arrays for city names, and bad words
let CITY_NAMES = [];
let BAD_WORDS = RedFlagList;

// Global state for managing popups
let activePopup = null;

const LinkSuggestion = ({
  children,
  contentState,
  entityKey,
  blockKey,
  start,
  end,
  setEditorState,
}) => {
  const [showPopup, setShowPopup] = useState(false);
  const popupRef = useRef(null);
  // const text = children[0].props.text;
  // const url = LINK_SUGGESTIONS[text];
  const text = children[0].props.text;
  const suggestionKey = Object.keys(LINK_SUGGESTIONS).find(
    (key) => key.toLowerCase() === text.toLowerCase()
  );
  const url = suggestionKey ? LINK_SUGGESTIONS[suggestionKey] : null;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowPopup(false);
      }
    };

    if (showPopup) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showPopup]);

  const handleApprove = () => {
    setEditorState((currentEditorState) => {
      const contentState = currentEditorState.getCurrentContent();
      const contentStateWithEntity = contentState.createEntity(
        "LINK",
        "MUTABLE",
        { url }
      );
      const newEntityKey = contentStateWithEntity.getLastCreatedEntityKey();
      const selectionState = SelectionState.createEmpty(blockKey).merge({
        anchorOffset: start,
        focusOffset: end,
      });
      const newContentState = Modifier.applyEntity(
        contentStateWithEntity,
        selectionState,
        newEntityKey
      );
      const newEditorState = EditorState.push(
        currentEditorState,
        newContentState,
        "apply-entity"
      );
      return EditorState.forceSelection(
        newEditorState,
        currentEditorState.getSelection()
      );
    });
    setShowPopup(false);
  };

  const handleReject = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowPopup(false);
  };

  const handleClick = (e) => {
    e.preventDefault();
    setShowPopup(true);
  };

  return (
    <span style={{ color: "orange", cursor: "pointer" }} onClick={handleClick}>
      {children}
      {showPopup && (
        <div
          className="suggestion-popup"
          ref={popupRef}
          style={{
            position: "absolute",
            backgroundColor: "white",
            border: "1px solid black",
            padding: "10px",
            zIndex: 1000,
          }}
        >
          Add link for "{text}"
          <br />
          <br />
          Link:{" "}
          <a href={url} target="_blank" rel="noreferrer">
            {url}
          </a>
          <br />
          <br />
          <button
            className="btn"
            style={{
              backgroundColor: "#4CAF50",
              color: "white",
              border: "none",
              padding: "8px 15px",
              marginRight: "10px",
              borderRadius: "4px",
              cursor: "pointer",
            }}
            onClick={handleApprove}
          >
            Approve
          </button>
          <button
            className="btn"
            onClick={handleReject}
            style={{
              backgroundColor: "#f44336",
              color: "white",
              border: "none",
              padding: "8px 15px",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            Reject
          </button>
        </div>
      )}
    </span>
  );
};

const Link = ({
  contentState,
  entityKey,
  children,
  blockKey,
  start,
  end,
  setEditorState,
}) => {
  const [showPopup, setShowPopup] = useState(false);
  const [isLinkValid, setIsLinkValid] = useState(null);
  const { url } = contentState.getEntity(entityKey).getData();
  const popupRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowPopup(false);
      }
    };

    if (showPopup) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showPopup]);

  useEffect(() => {
    if (showPopup) {
      checkLink(url);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPopup, url]);

  const isValidUrl = (string) => {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  };

  const checkLink = async (url) => {
    if (!isValidUrl(url)) {
      setIsLinkValid(false);
      return;
    }

    try {
      await fetch(url, {
        method: "HEAD",
        mode: "no-cors",
        cache: "no-cache",
        credentials: "omit",
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      });
      setIsLinkValid(true);
    } catch (error) {
      setIsLinkValid(false);
    }
  };

  const handleRemoveLink = () => {
    setEditorState((currentEditorState) => {
      const selection = currentEditorState.getSelection();
      const contentState = currentEditorState.getCurrentContent();

      // Create a new selection for the entire link
      const linkSelection = selection.merge({
        anchorKey: blockKey,
        focusKey: blockKey,
        anchorOffset: start,
        focusOffset: end,
      });

      // Remove the entity from the selected range without removing the text
      let newContentState = Modifier.applyEntity(
        contentState,
        linkSelection,
        null
      );

      // Remove the UNDERLINE style
      newContentState = Modifier.removeInlineStyle(
        newContentState,
        linkSelection,
        "UNDERLINE"
      );

      // Push the new content state to create a new editor state
      const newEditorState = EditorState.push(
        currentEditorState,
        newContentState,
        "apply-entity"
      );

      // Return the new state
      return EditorState.forceSelection(
        newEditorState,
        newEditorState.getSelection()
      );
    });
  };

  const handleOpenLink = () => {
    window.open(url, "_blank");
  };

  const handlePopupToggle = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowPopup(!showPopup);
  };

  return (
    <span
      style={{ color: "blue", textDecoration: "underline", cursor: "pointer" }}
      onClick={handlePopupToggle}
    >
      {children}
      {showPopup && (
        <div
          ref={popupRef}
          className="popup suggestion-popup"
          style={{
            position: "absolute",
            backgroundColor: "white",
            border: "1px solid black",
            padding: "10px",
            zIndex: 1000,
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <p>
            Link: {url}
            <br />
            <br />
            Status:
            {isLinkValid !== null && (
              <span
                style={{
                  display: "inline-block",
                  width: "10px",
                  height: "10px",
                  borderRadius: "50%",
                  backgroundColor: isLinkValid ? "green" : "red",
                  marginLeft: "5px",
                }}
              ></span>
            )}
          </p>
          <button
            className="btn"
            style={{
              backgroundColor: "#4CAF50",
              color: "white",
              border: "none",
              padding: "8px 15px",
              marginRight: "10px",
              borderRadius: "0.5rem",
              cursor: "pointer",
            }}
            onClick={handleOpenLink}
          >
            Open Link
          </button>
          <button
            className="btn"
            style={{
              backgroundColor: "#f44336",
              color: "white",
              border: "none",
              padding: "8px 15px",
              borderRadius: "0.5rem",
              cursor: "pointer",
            }}
            onClick={handleRemoveLink}
          >
            Remove Link
          </button>
        </div>
      )}
    </span>
  );
};

const findLinkEntities = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === "LINK"
    );
  }, callback);
};

const findSuggestionEntities = (contentBlock, callback) => {
  const text = contentBlock.getText();
  const regex = new RegExp(
    `\\b(${Object.keys(LINK_SUGGESTIONS).join("|")})\\b`,
    "gi"
  );
  let match;
  while ((match = regex.exec(text)) !== null) {
    const matchedText = match[0];
    const suggestionKey = Object.keys(LINK_SUGGESTIONS).find(
      (key) => key.toLowerCase() === matchedText.toLowerCase()
    );
    if (suggestionKey) {
      callback(match.index, match.index + matchedText.length);
    }
    // callback(match.index, match.index + match[0].length);
  }
};

const SearchPopup = ({ word, onClose }) => {
  const popupRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  const handleGoogleSearch = () => {
    window.open("https://www.price4limo.com/pages.html", "_blank");
  };

  return (
    <div
      ref={popupRef}
      style={{
        position: "absolute",
        backgroundColor: "white",
        border: "1px solid black",
        padding: "10px",
        borderRadius: "5px",
        boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
        zIndex: 1000,
      }}
    >
      <button
        className="btn"
        onClick={handleGoogleSearch}
        style={{
          backgroundColor: "#4285F4",
          color: "white",
          border: "none",
          padding: "5px 10px",
          borderRadius: "3px",
          cursor: "pointer",
        }}
      >
        Search: {word}
      </button>
    </div>
  );
};

const HighlightWithSearch = ({ children, backgroundColor, color }) => {
  const [showPopup, setShowPopup] = useState(false);
  const spanRef = useRef(null);

  // Extract the text content from the children prop
  const textContent = React.Children.toArray(children).reduce((acc, child) => {
    if (typeof child === "string") {
      return acc + child;
    }
    if (child.props && child.props.text) {
      return acc + child.props.text;
    }
    return acc;
  }, "");

  const handleClick = (event) => {
    setShowPopup(true);
    event.preventDefault();
  };

  return (
    <span
      ref={spanRef}
      style={{ backgroundColor, color, cursor: "pointer" }}
      onClick={handleClick}
    >
      {children}
      {showPopup && (
        <SearchPopup word={textContent} onClose={() => setShowPopup(false)} />
      )}
    </span>
  );
};

export const CityHighlight = ({ children }) => (
  <HighlightWithSearch backgroundColor="pink" color="black">
    {children}
  </HighlightWithSearch>
);

export const StateHighlight = ({ children }) => (
  <HighlightWithSearch backgroundColor="darkblue" color="white">
    {children}
  </HighlightWithSearch>
);

const BadWordHighlight = ({ children }) => (
  <span style={{ backgroundColor: "red", color: "white" }}>{children}</span>
);

// New strategies for finding entities
const findCityEntities = (contentBlock, callback, contentState) => {
  const text = contentBlock.getText();
  CITY_NAMES.forEach((city) => {
    const regex = new RegExp(`\\b${city}\\b`, "gi");
    let match;
    while ((match = regex.exec(text)) !== null) {
      callback(match.index, match.index + match[0].length);
    }
  });
};

const findBadWordEntities = (contentBlock, callback, contentState) => {
  const text = contentBlock.getText();
  BAD_WORDS.forEach((word) => {
    const regex = new RegExp(`\\b${word}\\b`, "gi");
    let match;
    while ((match = regex.exec(text)) !== null) {
      callback(match.index, match.index + match[0].length);
    }
  });
};

// Update the createDecorator function
const createDecorator = (setEditorState) =>
  new CompositeDecorator([
    {
      strategy: findLinkEntities,
      component: (props) => <Link {...props} setEditorState={setEditorState} />,
    },
    {
      strategy: findSuggestionEntities,
      component: (props) => (
        <LinkSuggestion {...props} setEditorState={setEditorState} />
      ),
    },
    {
      strategy: findCityEntities,
      component: CityHighlight,
    },
    {
      strategy: findBadWordEntities,
      component: BadWordHighlight,
    },
  ]);

const FormatDisplay = ({ editorState, wordCount }) => {
  const selection = editorState.getSelection();
  const content = editorState.getCurrentContent();
  const block = content.getBlockForKey(selection.getStartKey());
  const blockType = block.getType();
  const inlineStyle = editorState.getCurrentInlineStyle();

  const isLink = block.getEntityAt(selection.getStartOffset());
  const linkText = isLink ? "Link" : "";

  const getBlockTypeDisplay = (type) => {
    switch (type) {
      case "header-one":
        return "H1";
      case "header-two":
        return "H2";
      case "header-three":
        return "H3";
      case "header-four":
        return "H4";
      case "unstyled":
        return "Normal Text";
      default:
        return type;
    }
  };

  return (
    <div>
      <div style={{ marginBottom: "10px", fontSize: "14px" }}>
        Current Format:
        {getBlockTypeDisplay(blockType)}
        {inlineStyle.has("BOLD") && " Bold"}
        {inlineStyle.has("ITALIC") && " Italic"}
        {inlineStyle.has("UNDERLINE") && " Underline"}
        {linkText && ` ${linkText}`}
        &nbsp;&nbsp; Word Count: {wordCount}
        &nbsp;&nbsp; Selected Word Count: {getSelectedWordCount(editorState)}
      </div>
      <div className="instruction-section">
        <div>ℹ️</div>
        <div style={{ color: "orange" }}>Internal Link Suggestion</div>
        <div style={{ backgroundColor: "pink", borderRadius: "0.5rem" }}>
          City Name
        </div>
        <div style={{ backgroundColor: "red", borderRadius: "0.5rem" }}>
          Alert word
        </div>
      </div>
    </div>
  );
};

const LinkAdder = ({ onConfirm, onCancel }) => {
  const [url, setUrl] = useState("");

  const handleConfirm = () => {
    onConfirm(url);
    setUrl("");
  };

  return (
    <div
      style={{
        position: "absolute",
        backgroundColor: "white",
        border: "1px solid black",
        borderRadius: "4px",
        boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
        padding: "15px",
        zIndex: 1000,
      }}
    >
      <input
        type="text"
        value={url}
        onChange={(e) => setUrl(e.target.value)}
        placeholder="Enter URL"
        style={{
          width: "95%",
          padding: "8px",
          marginBottom: "10px",
          border: "1px solid #ddd",
          borderRadius: "4px",
        }}
      />
      <button
        className="btn"
        onClick={handleConfirm}
        style={{
          backgroundColor: "#4CAF50",
          color: "white",
          border: "none",
          padding: "8px 15px",
          marginRight: "10px",
          borderRadius: "0.5rem",
          cursor: "pointer",
        }}
      >
        Confirm
      </button>
      <button
        className="btn"
        onClick={onCancel}
        style={{
          backgroundColor: "#f44336",
          color: "white",
          border: "none",
          padding: "8px 15px",
          borderRadius: "0.5rem",
          cursor: "pointer",
        }}
      >
        Cancel
      </button>
    </div>
  );
};

const RichTextEditor = () => {
  const [editorState, setEditorState] = useState(() => {
    const decorator = createDecorator((newState) => setEditorState(newState));
    return EditorState.createEmpty(decorator);
  });
  const [showLinkAdder, setShowLinkAdder] = useState(false);
  const [linkAdderPosition, setLinkAdderPosition] = useState({
    top: 0,
    left: 0,
  });
  const [linkCounts, setLinkCounts] = useState({});
  const [wordCount, setWordCount] = useState(0);
  const [cityName, setCityName] = useState("");
  const [domainUrl, setDomainUrl] = useState(defaultWebsiteUrl);
  const [savedDomainUrl, setSavedDomainUrl] = useState(domainUrl);

  const editorRef = useRef(null);
  const popupRef = useRef(null);

  const navigateToLink = (url) => {
    const contentState = editorState.getCurrentContent();
    const blockMap = contentState.getBlockMap();

    let foundSelection = null;

    blockMap.forEach((contentBlock, blockKey) => {
      contentBlock.findEntityRanges(
        (character) => {
          const entityKey = character.getEntity();
          return (
            entityKey !== null &&
            contentState.getEntity(entityKey).getType() === "LINK" &&
            contentState.getEntity(entityKey).getData().url === url
          );
        },
        (start, end) => {
          foundSelection = SelectionState.createEmpty(blockKey).merge({
            anchorOffset: start,
            focusOffset: end,
          });
        }
      );
    });

    if (foundSelection) {
      const newEditorState = EditorState.forceSelection(
        editorState,
        foundSelection
      );
      setEditorState(newEditorState);

      const node = document.querySelector(
        `[data-offset-key="${foundSelection.getAnchorKey()}-0-0"]`
      );
      if (node) {
        node.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  };

  const handleCopy = (event, editorState) => {
    event.preventDefault();

    const content = editorState.getCurrentContent();
    const selection = editorState.getSelection();

    if (selection.isCollapsed()) {
      return;
    }

    const startKey = selection.getStartKey();
    const endKey = selection.getEndKey();
    const startOffset = selection.getStartOffset();
    const endOffset = selection.getEndOffset();

    let html = "";
    let plain = "";

    const blocks = content.getBlockMap().toArray();
    const selectedBlocks = blocks.slice(
      blocks.findIndex((block) => block.getKey() === startKey),
      blocks.findIndex((block) => block.getKey() === endKey) + 1
    );

    selectedBlocks.forEach((contentBlock, blockIndex) => {
      const blockText = contentBlock.getText();
      const blockKey = contentBlock.getKey();
      const blockStart = blockKey === startKey ? startOffset : 0;
      const blockEnd = blockKey === endKey ? endOffset : blockText.length;
      const blockType = contentBlock.getType();

      let blockHtml = "";
      let blockPlain = "";

      // Function to apply inline styles
      const applyInlineStyles = (text, charList) => {
        let styledText = "";
        for (let i = 0; i < text.length; i++) {
          let char = escapeHtml(text[i]);
          const charStyles = charList.get(i).getStyle();
          if (charStyles.has("BOLD")) {
            char = `<strong>${char}</strong>`;
          }
          if (charStyles.has("ITALIC")) {
            char = `<em>${char}</em>`;
          }
          if (charStyles.has("UNDERLINE")) {
            char = `<u>${char}</u>`;
          }
          styledText += char;
        }
        return styledText;
      };

      // Get all entity ranges in the block
      const entityRanges = [];
      const charList = contentBlock.getCharacterList();
      contentBlock.findEntityRanges(
        (character) => character.getEntity() !== null,
        (start, end) => {
          if (start < blockEnd && end > blockStart) {
            entityRanges.push({ start, end });
          }
        }
      );

      // Sort entity ranges by start index
      entityRanges.sort((a, b) => a.start - b.start);

      // Process text, entities, and inline styles in order
      let lastIndex = blockStart;
      entityRanges.forEach((range) => {
        const { start, end } = range;

        // Add styled text before the entity
        if (lastIndex < start) {
          const text = blockText.slice(
            Math.max(lastIndex, blockStart),
            Math.min(start, blockEnd)
          );
          const styledText = applyInlineStyles(
            text,
            charList.slice(
              Math.max(lastIndex, blockStart),
              Math.min(start, blockEnd)
            )
          );
          blockHtml += styledText;
          blockPlain += text;
        }

        // Process the entity
        if (start < blockEnd && end > blockStart) {
          const entityKey = contentBlock.getEntityAt(start);
          const entity =
            entityKey !== null ? content.getEntity(entityKey) : null;
          const entityType = entity !== null ? entity.getType() : null;

          if (entityType === "LINK") {
            const entityData = entity.getData();
            const url = entityData.url || "";
            const text = blockText.slice(
              Math.max(start, blockStart),
              Math.min(end, blockEnd)
            );
            const styledText = applyInlineStyles(
              text,
              charList.slice(
                Math.max(start, blockStart),
                Math.min(end, blockEnd)
              )
            );

            blockHtml += `<a href="${escapeHtml(url)}">${styledText}</a>`;
            blockPlain += text;
          } else {
            const text = blockText.slice(
              Math.max(start, blockStart),
              Math.min(end, blockEnd)
            );
            const styledText = applyInlineStyles(
              text,
              charList.slice(
                Math.max(start, blockStart),
                Math.min(end, blockEnd)
              )
            );
            blockHtml += styledText;
            blockPlain += text;
          }
        }

        lastIndex = end;
      });

      // Add any remaining styled text after the last entity
      if (lastIndex < blockEnd) {
        const text = blockText.slice(lastIndex, blockEnd);
        const styledText = applyInlineStyles(
          text,
          charList.slice(lastIndex, blockEnd)
        );
        blockHtml += styledText;
        blockPlain += text;
      }

      // Wrap the block content in the appropriate header tag if it's a header
      switch (blockType) {
        case "header-one":
          html += `<h1>${blockHtml}</h1>`;
          break;
        case "header-two":
          html += `<h2>${blockHtml}</h2>`;
          break;
        case "header-three":
          html += `<h3>${blockHtml}</h3>`;
          break;
        case "header-four":
          html += `<h4>${blockHtml}</h4>`;
          break;
        default:
          html += `<p>${blockHtml}</p>`;
      }

      plain +=
        blockPlain + (blockIndex < selectedBlocks.length - 1 ? "\n" : "");
    });

    event.clipboardData.setData("text/html", html);
    event.clipboardData.setData("text/plain", plain);
  };

  const escapeHtml = (unsafe) => {
    return unsafe
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;")
      .replace(/'/g, "&#039;");
  };

  const handleCopyWrapper = useCallback(
    (event) => {
      handleCopy(event, editorState);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editorState]
  );

  useEffect(() => {
    const editorElement = editorRef.current;
    if (editorElement) {
      editorElement.addEventListener("copy", handleCopyWrapper);
      return () => {
        editorElement.removeEventListener("copy", handleCopyWrapper);
      };
    }
  }, [handleCopyWrapper]);

  useEffect(() => {
    updateLinkCounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorState]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowLinkAdder(false);
      }
      if (activePopup) {
        activePopup();
        activePopup = null;
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const updateLinkCounts = useCallback(() => {
    const content = editorState.getCurrentContent();
    const newLinkCounts = {};

    content.getBlockMap().forEach((block) => {
      block.findEntityRanges(
        (character) => {
          const entityKey = character.getEntity();
          return (
            entityKey !== null &&
            content.getEntity(entityKey).getType() === "LINK"
          );
        },
        (start, end) => {
          const entityKey = block.getEntityAt(start);
          const { url } = content.getEntity(entityKey).getData();
          newLinkCounts[url] = (newLinkCounts[url] || 0) + 1;
        }
      );
    });

    setLinkCounts((prevCounts) => {
      if (JSON.stringify(prevCounts) !== JSON.stringify(newLinkCounts)) {
        return newLinkCounts;
      }
      return prevCounts;
    });
  }, [editorState]);

  useEffect(() => {
    updateLinkCounts();
  }, [updateLinkCounts]);

  const onChange = (newEditorState) => {
    setEditorState(newEditorState);
  };

  const calculateWordCount = useCallback((editorState) => {
    const plainText = editorState.getCurrentContent().getPlainText("");
    const wordArray = plainText.match(/\S+/g);
    return wordArray ? wordArray.length : 0;
  }, []);

  useEffect(() => {
    const newWordCount = calculateWordCount(editorState);
    setWordCount(newWordCount);
  }, [editorState, calculateWordCount]);

  const handleKeyCommand = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      onChange(newState);
      return "handled";
    }
    return "not-handled";
  };

  const keyBindingFn = (e) => {
    if (e.keyCode === 13 /* `Enter` key */) {
      return "split-block";
    }
    return getDefaultKeyBinding(e);
  };

  const toggleBlockType = (blockType) => {
    onChange(RichUtils.toggleBlockType(editorState, blockType));
  };

  const toggleInlineStyle = (inlineStyle) => {
    onChange(RichUtils.toggleInlineStyle(editorState, inlineStyle));
  };

  const addLink = (e) => {
    e.preventDefault();
    const selection = editorState.getSelection();
    if (selection.isCollapsed()) {
      alert("Please select some text first");
      return;
    }

    const editorBounds = editorRef.current.getBoundingClientRect();
    const newPosition = {
      top: editorBounds.top + window.pageYOffset + 40, // Position below the toolbar
      left: editorBounds.left + window.pageXOffset,
    };
    setLinkAdderPosition(newPosition);

    setShowLinkAdder(true);
  };

  const confirmLink = (url) => {
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      "LINK",
      "MUTABLE",
      { url }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity,
    });
    setEditorState(
      RichUtils.toggleLink(
        newEditorState,
        newEditorState.getSelection(),
        entityKey
      )
    );
    setShowLinkAdder(false);
  };

  const removeLink = () => {
    const selection = editorState.getSelection();
    if (!selection.isCollapsed()) {
      const contentState = editorState.getCurrentContent();
      const startKey = selection.getStartKey();
      const startOffset = selection.getStartOffset();
      const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
      const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);

      let newContentState;

      if (linkKey) {
        newContentState = Modifier.applyEntity(contentState, selection, null);
      } else {
        // If no link is found at the start, check the entire selection
        newContentState = contentState;
        let foundLink = false;
        selection.toArray().forEach((offset) => {
          const currentLinkKey = blockWithLinkAtBeginning.getEntityAt(offset);
          if (currentLinkKey) {
            foundLink = true;
            newContentState = Modifier.applyEntity(
              newContentState,
              SelectionState.createEmpty(startKey).merge({
                anchorOffset: offset,
                focusOffset: offset + 1,
              }),
              null
            );
          }
        });
        if (!foundLink) {
          return; // No links found, exit the function
        }
      }

      // Remove the UNDERLINE style
      newContentState = Modifier.removeInlineStyle(
        newContentState,
        selection,
        "UNDERLINE"
      );

      const newEditorState = EditorState.push(
        editorState,
        newContentState,
        "apply-entity"
      );
      setEditorState(newEditorState);
    }
  };

  const refresh = (cityName) => {
    const citiesArray = cityName.split(",").map((city) => city.trim());
    CITY_NAMES = [...CITY_NAMES, ...citiesArray];
    setCityName("");
    const currentContent = editorState.getCurrentContent();
    const newEditorState = EditorState.createWithContent(
      currentContent,
      createDecorator((newState) => setEditorState(newState))
    );
    setEditorState(newEditorState);
  };

  // This function toggles all highlight based on the flag passed
  const refreshHighlight = (showHighlight) => {
    if (showHighlight) {
      CITY_NAMES = [];
      BAD_WORDS = RedFlagList;
      LINK_SUGGESTIONS = LinkSuggestionList;
    } else {
      CITY_NAMES = [];
      BAD_WORDS = [];
      LINK_SUGGESTIONS = { xwer: "ssdsdsdsd.com" };
    }
    const currentContent = editorState.getCurrentContent();
    const newEditorState = EditorState.createWithContent(
      currentContent,
      createDecorator((newState) => setEditorState(newState))
    );
    setEditorState(newEditorState);
  };

  // const updateCMSLink = () => {
  //   const newEditorState = updateHyperlinks(editorState);
  //   setEditorState(newEditorState);
  // }

  const handlePastedText = useCallback((text, html, editorState) => {
    if (html) {
      // Convert the pasted HTML to ContentState
      const blocksFromHTML = convertFromHTML(html);

      // Process each block to preserve line breaks
      const processedBlocks = blocksFromHTML.contentBlocks
        .map((block) => {
          const text = block.getText();
          const lines = text.split("\n");
          if (lines.length > 1) {
            return lines.map(
              (line) =>
                new ContentBlock({
                  type: "unstyled",
                  text: line,
                })
            );
          }
          return block;
        })
        .flat();

      let newContentState = ContentState.createFromBlockArray(
        processedBlocks,
        blocksFromHTML.entityMap
      );

      // Get the current selection
      const selection = editorState.getSelection();
      const currentContent = editorState.getCurrentContent();

      // Insert the new content at the current selection
      newContentState = Modifier.replaceWithFragment(
        currentContent,
        selection,
        newContentState.getBlockMap()
      );

      // Create a new EditorState with the updated content
      const newEditorState = EditorState.push(
        editorState,
        newContentState,
        "insert-fragment"
      );

      // Update the editor state
      setEditorState(newEditorState);
      return "handled";
    }
    return "not-handled";
  }, []);

  // const updateHyperlinks = (editorState) => {
  //   let contentState = editorState.getCurrentContent();
  //   const blockMap = contentState.getBlockMap();

  //   let contentStateModified = false;

  //   blockMap.forEach((contentBlock) => {
  //     contentBlock.findEntityRanges(
  //       (character) => {
  //         const entityKey = character.getEntity();
  //         return (
  //           entityKey !== null &&
  //           contentState.getEntity(entityKey).getType() === 'LINK'
  //         );
  //       },
  //       (start, end) => {
  //         const entityKey = contentBlock.getEntityAt(start);
  //         const entity = contentState.getEntity(entityKey);
  //         const { url } = entity.getData();

  //         if (url && url.includes('/locations')) {
  //           const domainRegex = /^https?:\/\/www\.price4limo\.com/i;
  //           const newUrl = url.replace(domainRegex, '');

  //           if (newUrl !== url) {
  //             contentState = contentState.mergeEntityData(entityKey, { url: newUrl });
  //             contentStateModified = true;
  //           }
  //         }
  //       }
  //     );
  //   });

  //   if (contentStateModified) {
  //     const newEditorState = EditorState.push(editorState, contentState, 'apply-entity');
  //     return newEditorState;
  //   }

  //   return editorState;
  // };

  const setNormalText = () => {
    const newEditorState = RichUtils.toggleBlockType(editorState, "unstyled");
    onChange(newEditorState);
  };

  return (
    <div style={{ display: "flex" }}>
      <div style={{ flex: 1, marginRight: "270px", padding: "20px" }}>
        <div className="sticky-header" style={{ marginBottom: "20px" }}>
          <button
            className="btn"
            onClick={() => toggleInlineStyle("BOLD")}
            style={buttonStyle}
          >
            Bold
          </button>
          <button
            className="btn"
            onClick={() => toggleInlineStyle("ITALIC")}
            style={buttonStyle}
          >
            Italic
          </button>
          <button
            className="btn"
            onClick={() => toggleInlineStyle("UNDERLINE")}
            style={buttonStyle}
          >
            Underline
          </button>
          <button
            className="btn"
            onClick={() => setNormalText()}
            style={buttonStyle}
          >
            Normal
          </button>
          <button
            className="btn"
            onClick={() => toggleBlockType("header-one")}
            style={buttonStyle}
          >
            H1
          </button>
          <button
            className="btn"
            onClick={() => toggleBlockType("header-two")}
            style={buttonStyle}
          >
            H2
          </button>
          <button
            className="btn"
            onClick={() => toggleBlockType("header-three")}
            style={buttonStyle}
          >
            H3
          </button>
          <button
            className="btn"
            onClick={() => toggleBlockType("header-four")}
            style={buttonStyle}
          >
            H4
          </button>
          <button className="btn" onClick={addLink} style={buttonStyle}>
            Add Link
          </button>
          <button className="btn" onClick={removeLink} style={buttonStyle}>
            Remove Link
          </button>
          <br />
          <input
            className="city-remover-input"
            type="text"
            placeholder="Enter City Name "
            value={cityName}
            onChange={(e) => setCityName(e.target.value)}
          />
          <button
            className="btn"
            onClick={() => refresh(cityName)}
            style={buttonStyle}
          >
            Add City 🏛️
          </button>
          <button
            className="btn"
            onClick={() => refreshHighlight(true)}
            style={buttonStyle}
          >
            Show Highlight 🔍
          </button>
          <button
            className="btn"
            onClick={() => refreshHighlight(false)}
            style={buttonStyle}
          >
            Remove Highlight ❌
          </button>
          <br />
          <input
            className="city-remover-input"
            type="text"
            placeholder="Enter your website url"
            value={domainUrl}
            onChange={(e) => setDomainUrl(e.target.value)}
          />
          <button
            className="btn"
            onClick={() => {
              const newLinkSuggestionList = getNewSuggestionList(domainUrl);
              LINK_SUGGESTIONS = newLinkSuggestionList;
              setSavedDomainUrl(domainUrl);
            }}
            style={{ ...buttonStyle, backgroundColor: "green" }}
          >
            Save site
          </button>
          <button
            className="btn"
            onClick={() =>
              window.open(plagiarismCheckerPageUrl, "_blank", "noreferrer")
            }
            style={{ ...buttonStyle, backgroundColor: "orange" }}
          >
            Plagiarism Checker
          </button>
          {/* <button className='btn' onClick={() => updateCMSLink()} style={buttonStyle}>CMS Link Update 🎩</button> */}
          <FormatDisplay editorState={editorState} wordCount={wordCount} />
        </div>
        <div
          style={{
            border: "1px solid #ced4da",
            borderRadius: "4px",
            padding: "15px",
            position: "relative",
            minHeight: "200px",
            backgroundColor: "white",
          }}
          ref={editorRef}
        >
          <Editor
            editorState={editorState}
            onChange={onChange}
            handleKeyCommand={handleKeyCommand}
            keyBindingFn={keyBindingFn}
            handlePastedText={handlePastedText}
          />
        </div>
      </div>
      {showLinkAdder && (
        <div
          className="link-suggestion-block"
          ref={popupRef}
          style={{
            position: "fixed",
            top: `${linkAdderPosition.top}px`,
            left: `${linkAdderPosition.left}px`,
            zIndex: 1000,
          }}
        >
          <LinkAdder
            onConfirm={confirmLink}
            onCancel={() => setShowLinkAdder(false)}
          />
        </div>
      )}
      <LinkCountSidebar
        linkCounts={linkCounts}
        onLinkClick={navigateToLink}
        domainUrl={savedDomainUrl}
      />
    </div>
  );
};

const buttonStyle = {
  backgroundColor: "rgb(0, 123, 255)",
  // boxShadow:
  //   "rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.06) 0px 1px 1px 0px, rgba(225, 101, 64, 0.157) 0px 0px 0px 1px, rgba(225, 101, 64, 0.64) 0px 8px 16px -8px, rgba(181, 81, 51, 0.48) 0px -1px 2px 0px inset",
  outlineColor: "rgb(65.3083% 0.163661 37.224155)",
  color: "white",
  border: "none",
  padding: "8px 15px",
  marginRight: "10px",
  borderRadius: ".5rem",
  cursor: "pointer",
  marginBottom: "10px",
  // fontSize: '1rem',
  lineHeight: "1.5rem",
  fontWeight: "500",
  // "hover:" : 'rgb(196, 87, 54)'
};

export default RichTextEditor;
